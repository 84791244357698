body {
  background: rgb(243,243,243);
  background: linear-gradient(0deg, rgba(243,243,243,1) 0%, rgba(172,201,225,1) 100%);
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-header {
  font-size: 30px;
  color: #fff;
}
.Boxes {
  display: flex;
  justify-content: center;
  gap: 20px;
}

textarea {
  border: 10px solid #fff;
  background-color: transparent;
  min-width: 40vw;
  max-width: calc(50vw -10);
  padding: 10px;
  min-height: 30vh;
  box-sizing: border-box;
}

::placeholder {
  line-height: 20vh;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #fff;
}

.result {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.fifty {
  flex-grow: 1;
  width: 50%;
}

.fifty:first-child {
  text-align: right;
}

.fifty:last-child {
  text-align: left;
}